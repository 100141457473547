.text-white {
  color:white !important;
  &.fa{
    color:white !important
  }
}

.loginPage {
    z-index: 10;
    width: 100%;
    padding-left: 13px;
    // padding-right: 9%;
    @media (max-width: 1610px) {
      // padding-right: 7%;
    }
    @media(max-width: 980px){
      padding: 0;
    }
  
    .loginPage-inner {
      display: flex;
      width: 100%;
      justify-content: space-around;
      align-items: center;
      @media(max-width: 1900px) {
        justify-content: space-between;
      }
      @media (max-width: 980px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  
    .loginImageContainer {
      // max-width: 700px;
      // width: 50%;
      background-color: transparent;
      @media (max-width: 980px) {
        margin-bottom: 20px;
        width: 100%;
      }
  
      img {
        width: 100%;
      }
    }
  
    .loginPage-form {
      // max-width: 480px;
      // width: 45%;
      @media (max-width: 980px) {
        // width: 80%;
      }
      @media (max-width: 500px) {
        width: 100%;
      }
      transform: scale(1.1);
      @media(max-width: 1600px) {
        transform: scale(1);
      }
  
      .cardHeader {
        margin-bottom: 20px;
        padding-top: 0;
        @media (max-width: 1610px) {
          margin-bottom: 0;
        }
  
        h3, p {
          padding: 0;
          margin: 0;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.44;
          letter-spacing: 1.56px;
          text-align: left;
        }
  
        h3 {
          font-size: 38px;
          color: #28245d;
          font-weight: 600;
        }
  
        p {
          font-size: 27px;
          color: #919191;
          font-weight: 500;
        }
      }
  
      .cardBody {
        padding-bottom: 0 !important;
      }
  
      .formCard {
        box-shadow: none;
        background-color: transparent;
        margin: 0;
      }
  
      .login-button {
        border-radius: 10px;
        background-color: #28245d;
        width: 100%;
        height: 64px;
        font-size: 31px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.16;
        letter-spacing: 1.24px;
        text-align: center;
        color: #ffffff;
        text-transform: unset;
        margin: 0;
        transition: all 100ms ease-in-out;
  
        &:hover {
          color: #fff;
          font-size: 36px;
        }
      }
    }
  }


  





  @import url( 'https://fonts.googleapis.com/css?family=Nunito');

$default-height: 80%;
$default-width: 25%;
$level2-height: $default-height - 8%;
$level2-width: $default-width - 8%;
$level1-height: $default-height - 2%;
$level1-width: $default-width - 2%;
$level0-height: $default-height + 5%;
$level0-width: $default-width + 5%;
$level-2-left: 15%;
$level-1-left: 30%;
$level0-left: 50%;
$level1-left: 70%;
$level2-left: 85%;
$level-2-bs: -.1em .1em .2em #212121;
$level-1-bs: -.2em .2em .4em #212121;
$level2-bs: .1em .1em .2em #212121;
$level1-bs: .2em .2em .4em #212121;
$level0-bs: 0 .4em .8em #212121;

// body {
//     padding-top: 1em;
//     background-color: #673AB7;
//     font-family: 'Nunito';
// }

.basedOn {
  text-align: center;
  font-size: 1.2em;
  color: #FFF;
  a, a:visited, a:hover, a:active {
    color: #FFEB3B;
  }
}

#carousel {
    height: 100%;
    width: 100%;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.arrow {
  position: absolute;
  width: 40px;
  height: 40px;
  /* background-color: #673AB7; */
  text-align: center;
  cursor: pointer;
  line-height: 30px;
  z-index: 1000;
  top: 300px;
  transform: translate(0, -50%);
  transition: all 500ms;
  border: 1px solid #707070;
  border-radius: 50%;
  margin: 10px;
  font-size: 16px;
  padding: 10px;
  color: #707070;
    i {
      transform: translate(-50%, -50%);
      top: 50%;
      left: 50%;
      position: absolute;
    }
    transition: all 500ms;
    &-right {
        right: 0;
    }

    &-left {
        left: 0;
    }
  &:hover{
    background-color: #292562;
    color: #ffffff;
  }
}

.item {
    text-align: center;
    color: white;
    font-size: 40px;
    position: absolute;
    left: 50%;
    top: 150px;
    transform: translate(-50%, -50%);
    transition: box-shadow 1s,  height 250ms, width 250ms, left 1s, margin-top 1s, line-height 250ms, background-color 1s;
}

.level-2 {
    box-shadow: $level-2-bs;
    height: $level2-height;
    width: $level2-width;
    line-height: $level2-height;
    // background-color: #228291;
    left: $level-2-left;
}

.level-1 {
    box-shadow: $level-1-bs;
    height: $level1-height;
    width: $level1-width;
    line-height: $level1-height;
    // background-color: #6796E5;
    left: $level-1-left;
}

.level0 {
    box-shadow: $level0-bs;
    height: $level0-height;
    width: $level0-width;
    line-height: $level0-height;
    // background-color: #4EC9E1;
    left: $level0-left;
}

.level1 {
    box-shadow: $level1-bs;
    height: $level1-height;
    width: $level1-width;
    line-height: $level1-height;
    // background-color: #6796E5;
    left: $level1-left;
}

.level2 {
    box-shadow: $level2-bs;
    height: $level2-height;
    width: $level2-width;
    line-height: $level2-height;
    // background-color: #228291;
    left: $level2-left;
}

.level-2,
.level2 {
    z-index: 0;
}

.level-1,
.level1 {
    z-index: 1;
}

.level0 {
    z-index: 2;
}

.left-enter {
    opacity: 0;
    left: $level2-left;
    height: $level2-height - $default-height;
    width: $level2-width - $default-width;
    line-height: $level2-height - 30;

    &.left-enter-active {
        opacity: 1;
        left: $level2-left;
        height: $level2-height;
        width: $level2-width;
        line-height: $level2-height;
        transition: box-shadow 1s,  left 1s, opacity 1s, height 250ms, width 250ms, margin-top 1s, line-height 1s;
    }
}

.left-leave {
    opacity: 1;
    left: $level-2-left;
    height: $level2-height;
    width: $level2-width;
    line-height: $level2-height;
    z-index: -1;

    &.left-leave-active {
        z-index: -1;
        left: $level-2-left;
        opacity: 0;
        height: $level2-height - $default-height;
        width: $level2-width - $default-width;
        line-height: 120px;
        transition: box-shadow 1s,  left 1s, opacity 1s, height 250ms, width 250ms, margin-top 1s, line-height 1s;
    }
}

.right-enter {
    opacity: 0;
    left: $level-2-left;
    height: $level2-height - $default-height;
    width: $level2-width - $default-width;
    line-height: $level2-height - 30;

    &.right-enter-active {
        left: $level-2-left;
        opacity: 1;
        height: $level2-height;
        line-height: $level2-height;
        width: $level2-width;
        transition: box-shadow 1s,  left 1s, opacity 1s, height 250ms, width 250ms, margin-top 1s, line-height 1s;
    }
}

.right-leave {
    left: $level2-left;
    height: $level2-height;
    opacity: 1;
    line-height: $level2-height;
    width: $level2-width;

    &.right-leave-active {
        left: $level2-left;
        opacity: 0;
        height: $level2-height - $default-height;
        width: $level2-width - $default-width;
        line-height: $level2-height - 30;
        transition: box-shadow 1s,  left 1s, opacity 1s, height 250ms, width 250ms, margin-top 1s, line-height 1s;
    }
}

.noselect {
    -webkit-user-select: none;
    -html-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.react-pdf__Page .react-pdf__Page__canvas {
  height: inherit !important;
}
.react-pdf__Document {
  border: 1px solid #000;
}
// .arrow-left {
//   left: 90%;
// }
.arrow-right {
  right: 23px;
}


// QL Editor
.ql-editor {
  min-height: 150px;
  background: white;
}
.slide-main {
  position: relative;

  .slide-docType {
    position: relative;
    z-index: 99;
    color: #fff;
    padding: 8px 17px;
    font-size: 21px;
    border-radius: 6px 6px 0px 0;
}

  .slider-overLay{
    position: absolute;
    z-index: 99;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.3);
    transition: all 0.5s ease;
    visibility: hidden;
    opacity: 0;
    > div {
      position: relative;
      .btn {
        margin: 0 5px;
      }
      .btn-approval {
        .fa {
          color: rgba(255,255,255,1);
        }
      }
    }
    
  }
  &:hover {
    .slider-overLay{
      opacity: 1;
      visibility: visible;
    }
  }
}
.rejectFormHolder {
  label {
    display: none;

  }
  textarea{
    height: 90px !important;
    margin-top: 20px;
    resize: none;
  }

}

.reqSearchHeader{
  padding: 10px;
  background: #fff;
  .form-group {
    margin: 0;
}
  label{
    display: none;
  }
}


/**** ====== Fresh Chat widget css ====== ****/  
#fc_frame, #fc_frame.fc-widget-normal {
   
  width: 100px !important;
  height: 119px !important;
}

#fc_frame.fc-widget-small.h-open-container, #fc_frame.h-open-container {
  max-height: calc(100% - 100px);
  opacity: 1;
  height: 772px !important;
  width: 866px !important;
}

.verification-tab-content{
  .verification-doc-item {
    max-width: 550px;
    display: block;
    margin: 30px 0 !important;
    position: relative;
    .react-pdf__Document.loading-pdf {
      box-shadow: none;
     
  }
  .slider-overLay{
    position: absolute;
    z-index: 99;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.3);
    transition: all 0.5s ease;
    visibility: hidden;
    opacity: 0;
    > div {
      position: relative;
      .btn {
        margin: 0 5px;
      }
      .btn-approval {
        .fa {
          color: rgba(255,255,255,1);
        }
      }
    }
    
  }
  &:hover {
    .slider-overLay{
      opacity: 1;
      visibility: visible;
    }
  }
  }
.input-group {

  .form-group {
    margin: 0;
  
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
    
    > div,
    label {
      display: none;
    }
  }
}
.cv-upload {
  position: relative;
  min-width: 200px;
  margin: 0;
  overflow: hidden;
  cursor: pointer;
  input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0 !important;
    cursor: pointer;
    z-index: 999;
  }
  img {
    width: 28px;
    margin: 5px;
    position: relative;
    z-index: 0;
  }
}
}

.table-head.condition-table {
  .react-datepicker__input-container {
    .input {
      height:37.31px !important;
    }
  }
}

.onBoardModel {
  textarea.form-control {
    height: 100px !important;
  }
}
.SearchReqForm {
padding-bottom:140px !important;
}