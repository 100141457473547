.ssologin-card-box-shadow {
  -webkit-box-shadow: 0 35px 20px #777;
  -moz-box-shadow: 0 35px 20px #777;
  box-shadow: 0 35px 20px #777;
}

.ssologin-card-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 351px) {
  .ssologin-card-box-dimensions {
    width: 32rem;
    height: 32rem;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 350px) {
  .ssologin-card-box-dimensions {
    width: 95%;
    height: 32rem;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 241px) {
  .ssologin-button-responsive {
    margin-bottom: 2px;
  }
}

.ssologin-card-body {
  flex: unset !important;
}

.ssologin-button-text {
  font-weight: bold !important;
}

.ssologin-img-logo {
  max-width: fit-content;
}
