/* @import './tailwind.css'; */

.primary {
    color: var(--color-primary);
}

.secondary {
    color: var(--color-secondary);
}

.text-primary {
    color: var(--color-text-primary);
}

.primary-background {
    background-color: var(--background-primary);
}

.text-secondary {
    color: var(--color-text-secondary);
}

.btn-primary{
    background-color: var(--color-primary);
    color: var(--color-text-primary);
    border: none;
}

.nav-drop-menus .dropdown-btn,
.nav-drop-menus .dropdown-btn:hover,
.nav-drop-menus .menus-links a,
.nav-drop-menus .menus-links a:hover{
    color: var(--color-text-primary);
}

.side-menus .nav-item .nav-link{
    color: var(--color-text-primary);
}

.primary-btn:hover{
    background-color: var(--color-primary);
    color: var(--color-text-primary);
}

.btn-bulk-upload{
    background-color: var(--color-primary);
    color: var(--color-text-primary);
}

.btn-approval{
    background-color: var(--color-primary);
    color: var(--color-text-primary);
}

.onboard-btn{
    background-color: var(--color-primary);
    color: var(--color-text-primary);
}

.master-table-head .tab-content .nav-item .nav-link.active
{
    background-color: var(--color-primary);
    color: var(--color-text-primary);
}

.table-head{
    background-color: var(--color-primary);
    color: var(--color-text-primary);
}

.btn-admin-das{
    background-color: var(--color-secondary);
    color: var(--color-text-secondary);
}

.btn-add-condition{
    background-color: var(--color-secondary);
    color: var(--color-text-secondary);
}

.btn-add-condition:hover{
    background-color: var(--color-secondary);
    color: var(--color-text-secondary);
}

input:checked + .slider{
    background-color: var(--color-secondary);
}

.slider.round:before{
    background-color: var(--color-primary)
}

.switch3 input:checked + .slider{
    background-color: var(--color-secondary);
}

.switch3 .slider::before{
    background-color: var(--color-primary)
}